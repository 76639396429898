import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Accordion, Card } from 'react-bootstrap'

import { siteMetadata } from '../../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Medemer from 'components/medemer/am'

class Terms extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="About" />

        <div>
          <section className="jumbotron text-left">
            <div className="container">
              <h1 className="jumbotron-heading">Terms and Conditions</h1>
              <p className="lead text-muted">&nbsp;</p>
            </div>
          </section>

          <div className="container marketing">
            <div className="row featurette">
              <div class="col-md-12">
                <p>
                  THESE TERMS AND CONDITIONS OF USE ("TERMS OF USE") APPLY TO
                  ALL USERS OF THIS WEBSITE, MEDEMER.ET, PLEASE READ THEM
                  CAREFULLY BEFORE USING THE SITE.
                </p>

                <p>
                  <strong>ABOUT US</strong>
                </p>
                <p>
                  Medemer.et is owned Dr Abiy Ahmed in Addis Ababa, Ethiopia.
                </p>

                <p>
                  <strong>USE OF THE SITE</strong>
                </p>
                <p>
                  All users of this website agree that access to and use of this
                  website are subject to these Terms of Use, any related terms
                  such as the Privacy Policy and any applicable laws.
                </p>
                <p>
                  We aim to update our site regularly and reserve the right to
                  modify these Terms of Use at any time but will always note the
                  date of last revision. Please ensure that you check these
                  Terms regularly to view any changes, because by continuing to
                  use the site after these Terms and Conditions have changed,
                  you will be confirming that you have read and understood, and
                  agreed to be bound by, any revised Terms and Conditions.
                </p>
                <p>
                  If you do not agree to these Terms of Use, as may be updated,
                  please do not use this website.
                </p>

                <p>
                  <strong>MATERIAL ON THE SITE</strong>
                </p>
                <p>
                  All text, graphics, audio, video or image files, content,
                  software, applications and information displayed on or
                  available from this site, and all copyright, trade mark
                  rights, design rights and other intellectual property rights
                  in them (together, the "Materials") are the property of Dr
                  Abiy Ahmed or its suppliers or licensors.
                </p>
                <p>
                  The name “Medemer" and logo (the "Marks") belong solely to Dr
                  Abiy Ahmed.
                </p>
                <p>
                  Whilst Medemer encourages the reproduction and distribution of
                  the information and materials available on this site, such
                  uses have limitations and it is important that you read and
                  understand the following:
                </p>
                <ul>
                  <li>
                    you use the Materials for information purposes and for your
                    non-commercial, personal or educational use only.
                    <br />
                  </li>
                  <li>
                    all copyright, trade mark and other proprietary notices must
                    be kept on the Materials, for example when a Mark is used,
                    please include the line "_______ is a Trademark of Medemer"
                    visibly on the page.
                  </li>
                  <li>
                    you do not modify, reproduce, display, distribute the
                    Materials or perform the Materials for any public use
                    without prior written consent from Medemer.
                  </li>
                </ul>
                <p>
                  <strong>DISCLAIMER</strong>
                </p>
                <p>
                  The Site and Materials are provided on an 'as is' basis and
                  for information purposes only. Whilst every care is taken in
                  compiling the information on the site we do not make any
                  warranties or representations as to its accuracy or
                  reliability, and to the fullest extent permitted by law we
                  disclaim all warranties.
                </p>
                <p>
                  We may make changes to the Site and Material on the site at
                  any time without notice.
                </p>
                <p>
                  Certain information on the site may contain typographical or
                  other errors or be out of date, and we make no commitment to
                  update such information.
                </p>
                <p>
                  We and any third parties referred to on the Website assume no
                  responsibility for how you use the information provided
                  through the site.
                </p>

                <p>
                  <strong>THIRD PARTY LINKS</strong>
                </p>
                <p>
                  We may link to websites operated by third parties to provide
                  you with additional information and add value to your visit to
                  our site. However, even if the third party is affiliated with
                  Medemer in some way, we don't have any control over these
                  sites, we are not responsible for the content, products,
                  services or any advertising on them, or for any dealings or
                  disputes that you may have in relation to those sites. All
                  third party sites will have their own Terms of Use and Privacy
                  Policies and we would encourage you to review the terms and
                  conditions and privacy policy on any new site you may choose
                  to access. Any linked websites or content are only for your
                  convenience and as such, you access them at your own risk.
                  Medemer does not endorse or support the content of any third
                  party website.
                </p>

                <p>
                  <strong>PRIVACY</strong>
                </p>
                <p>
                  Medemer is committed to safeguarding your privacy. The terms
                  regulating the handling of personally identifiable information
                  and other information submitted by you in connection with this
                  website are described in our Privacy Policy.
                </p>

                <p>
                  <strong>INDEMNITY</strong>
                </p>
                <p>
                  You agree to indemnify, defend, and hold harmless, Medemer,
                  its officers, directors, employees, agents, licensors and
                  suppliers from and against all claims, losses, expenses,
                  damages and costs, including, without limitation, reasonable
                  attorneys' fees, arising from or relating in any way to your
                  use of this website, including your use of any text, graphic,
                  code, trademark, logo, design, or other Material as permitted
                  in these Terms of Use, to the maximum extent permitted by
                  applicable law.
                </p>

                <p>
                  <strong>LIMITATION OF LIABILITY</strong>
                </p>
                <p>
                  To the maximum extent permitted by law, you use the site at
                  your own risk and Medemer will not be liable to you for any
                  costs or losses you incur through your use of our site.
                  Despite this, in the event that Medemer is found to be liable
                  to you for any damage or loss which arises out of or is in any
                  way connected with your use of this website or any content for
                  any reason, Medemer's liability shall not exceed USD $100.00
                  in total.
                </p>
                <p>
                  No failure or delay by Medemer to enforce our rights under
                  these Terms is not a waiver and we remain entitled to enforce
                  our rights.
                  <br />
                </p>
                <p>
                  <strong>MISCELLANEOUS</strong>
                </p>
                <p>
                  These Terms and any dispute in relation to them are governed
                  by Ethiopian law and we reserve the right to bring proceedings
                  via any appropriate forum or in the Ethiopian courts. Your use
                  of this website will be governed by the laws of your country
                  of residence, or the country from which you are accessing the
                  site and as such you may have additional legal rights and/or
                  obligations than those provided under Ethiopian law. We would
                  encourage you to speak to us if you have any concerns but
                  would always recommend that you seek local legal advice if
                  there was ever a dispute that we couldn’t resolve between us.
                </p>
                <p>
                  If any part or provision of these Terms is or becomes illegal,
                  invalid or unenforceable, this shall not impair the legality,
                  validity and enforceability of all other provisions and parts
                  of these Terms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Terms
